<template>
   <MobileHeader  :mobileHeaderTitle="'NEWS'" ></MobileHeader>

  <div class="container news-container has-space has-header">
	<div class="news-header">
		<div class="sub-tab__wrapper">
			<router-link :to="{ name: o.pathName,}" v-for="(o, index) in newsSportType" :key="index" class="sub-tab" :class="{ active :o.selected }">{{ $t(o.name) }}</router-link>
		</div>
		<div
			class="news-language__selection-wrapper"
			v-if="!isMobile && newsLangSelect.selectedLangObj !== null"
			@mouseover="handleShowNewsLanguagelist('enter')"
			@mouseleave="handleShowNewsLanguagelist('leave')"
			@click="mobileToggleLangShow"
		>
			<div class="news-language__selected-pill">
				<div class="news-language__selected-name" >{{ newsLangSelect.selectedLangObj.displayName }}</div>
				<div class="news-language__list-caret" :class="{ active: newsLangSelect.isShowList }">
					<svg class="down-arrow" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0.94 0.226685L4 3.28002L7.06 0.226685L8 1.16668L4 5.16668L0 1.16668L0.94 0.226685Z" fill="white"/>
					</svg>
				</div>
			</div>
			<div class="news-language-list__wrapper" :class="{ active: newsLangSelect.isShowList }">
				<div
					class="news-language-list__container"
					v-for="(o, index) in currentLanguageList"
					:key="index"
					:class="{ selected: o.locale === newsLangSelect.selectedLangObj.locale }"
					@click="handleNewsLangSelect(o)"
				>
					<div class="news-language-list__name">{{ o.displayName }}</div>
					<div class="news-language-list__tick" :class="{ selected: o.locale === newsLangSelect.selectedLangObj.locale }"></div>
				</div>
			</div>
		</div>
	</div>
	<div v-show="!isLoading && !isShowNoData">
		<div   class="news-page-main-wrapper">
			<h1 class="news_top-stories__title">{{topStoriesList.name}}</h1>
			

		<Teleport to="#news-language-selection-portal" v-if="isMobile && newsLangSelect.selectedLangObj !== null">
			<div id="mobileNewsLangSelection" class="news-language__selection-wrapper">
				<div class="news-language__selected-pill">
					<div class="news-language__selected-name">{{ newsLangSelect.selectedLangObj.displayShorterName }}</div>
					<div class="news-language__list-caret" :class="{ active: newsLangSelect.isShowList }">
						<svg class="down-arrow" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.94 0.226685L4 3.28002L7.06 0.226685L8 1.16668L4 5.16668L0 1.16668L0.94 0.226685Z" fill="white"/>
						</svg>
					</div>
				</div>
			</div>
		</Teleport>

		</div>
		<div v-if="!isMobile" class="news_top-stories__wrapper">
			<swiper :slidesPerView="3" :navigation = "{nextEl: '#btnSwiperNextRounded', prevEl: '#btnSwiperPrevRounded'}"  :spaceBetween="18" :freeMode="false" class="mySwiper " :slidesPerGroup="3" :pagination="{ clickable: true }">
				<!--  :slidesPerGroup="3" :pagination="{ clickable: true }" -->
				<swiper-slide class="news_top-stories__container" v-for="(x, index) in topStoriesList.news" :key="index">
				<router-link :to="{ name: 'newsDetail', params: { title: x.urlName, id: x.id, currentSportType: x.sportTypePath }}">
					<div class="news_top-stories__img-wrapper">
						<img class="news_top-stories__img-coverImg" :src="`${s3ImgUrl}/${x.coverImage}`">
						<div class="news_top-stories__img-icon" v-if="x.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
					</div>
					<div class="news_top-stories__desc-wrapper">
						<p class="news_top-stories__desc-title">{{x.title}}</p>
						<div class="news_top-stories__desc-tag">
							<span>{{x.publishedOn}}</span>
						</div>
					</div>
					</router-link>
				</swiper-slide>                     						
			</swiper> 
			<div id="btnSwiperNextRounded" v-if="isShowTopStoriesSwiper" class="news__top-stories-btn next" ><img class="swiper-button__img" src="../../../static/images/icons/icon_arrow_right_white.png"></div>
			<div id="btnSwiperPrevRounded" v-if="isShowTopStoriesSwiper" class="news__top-stories-btn prev"><img  class="swiper-button__img" src="../../../static/images/icons/icon_arrow_left_white.png"></div>
		</div>
		<div v-else>
			<div class="mb-3rem">
				<swiper  :pagination="{ clickable: true }" :autoplay='{"delay": 5000, "disableOnInteraction": false}' class="relative news_top-stories__swipper ">
					<swiper-slide class="news_top-stories__container" v-for="(x, index) in topStoriesList.news" :key="index">
						<router-link :to="{ name: 'newsDetail', params: { title: x.urlName, id: x.id, currentSportType: x.sportTypePath }}">
							<div class="news_top-stories__img-wrapper">
								<img class="news_top-stories__img-coverImg" :src="`${s3ImgUrl}/${x.coverImage}`">
								<div class="news_top-stories__img-icon" v-if="x.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
							</div>
							<div class="news_top-stories__desc-wrapper">
								<p class="news_top-stories__desc-title">{{x.title}}</p>
								<div class="news_top-stories__desc-tag">
									<span>{{x.publishedOn}}</span>
								</div>
							</div>
						</router-link>
					</swiper-slide>                     						
				</swiper> 
			</div>
		</div>
		<div v-for="(o, index) in newsCateogoriesList" :key="index" class="news_latest-news">
			<div class="news_latest-news__title-wrapper">
				<h1 class="news_latest-news__title">{{o.name}}</h1>
				<div class="display-flex-center pointer" v-show="!o.isLatestNews">				  
					<router-link :to="{ name: 'newsCategories', params: {categoryName: o.urlName, categoryId: o.categoryId}}"  class="font-s mr-05rem">{{$t('SEE_MORE')}}</router-link>
					<img src="../../../static/images/icons/icon_arrow_right_white.png">
				</div>
			</div>
			<div class="news_latest-news__wrapper">
					<NewCategoriesCard :data="o.news"> </NewCategoriesCard>
				<!-- <router-link :to="{ name: 'newsDetail', params: { title: x.urlName, id: x.id}}"   class="news_latest-news__container" v-for="(x, index) in o.news" :key="index">
					<div class="news_latest-news__container-img">
						<img :src="`${s3ImgUrl}/${x.coverImage}`">
					</div>
					<div class="news_latest-news__container-desc">
						<p>{{x.title}}</p>
						<div>
							<span class="news_latest-news__container-desc_tag">{{x.sportType}}</span>
							<span class="news_latest-news__container-desc_tag">{{x.soccerLeagueName}}</span>
							<span class="news_latest-news__container-desc_tag">{{x.publishedOn}}</span>
						</div>
					</div>
				</router-link> -->
			</div>
		</div>
	</div>
  </div> 
  <Loading class="has-header" v-show="isLoading"></Loading>
    <div class="no-data has-header has-bottom" v-show="isShowNoData && !isLoading">{{$t('NO_NEWS')}}</div>
  <!-- <div  class="home has-header hide" >
	  <div class="container has-space ">
		<h1 class="news_top-stories__title">{{topStoriesList.name}}</h1>
		<div class="mb-3rem">
			<swiper  :pagination="{ clickable: true }" :autoplay='{"delay": 50000, "disableOnInteraction": false}' class="relative news_top-stories__swipper ">
				<swiper-slide class="news_top-stories__container" v-for="(x, index) in topStoriesList.news" :key="index">
					<div class="news_top-stories__img-wrapper">
						<img class="news_top-stories__img-coverImg" :src="`${s3ImgUrl}/${x.coverImage}`">
					</div>
					<div class="news_top-stories__desc-wrapper">
						<p class="news_top-stories__desc-title">{{x.title}}</p>
						<div class="news_top-stories__desc-tag">
							<span>{{x.publishedOn}}</span>
						</div>
					</div>
				</swiper-slide>                     						
			</swiper> 
		</div>
		<div v-for="(o, index) in newsCateogoriesList" :key="index" class="news_latest-news">
			<div class="news_latest-news__title-wrapper">
				<h1 class="news_latest-news__title">{{o.name}}</h1>
				<div class="display-flex-center pointer">				  
					<router-link :to="{ name: 'newsCategories', params: {categoryName: o.urlName, categoryId: o.categoryId}}"  class="font-s mr-05rem">{{$t('SEE_MORE')}}</router-link>
					<img src="../../../static/images/icons/icon_arrow_right_white.png">
				</div>
			</div>
			<div class="news_latest-news__wrapper">
				<router-link :to="{ name: 'newsDetail', params: { title:x.urlName, id: x.id }}"   class="news_latest-news__container" v-for="(x, index) in o.news" :key="index">
					<div class="news_latest-news__container-img">
						<img :src="`${s3ImgUrl}/${x.coverImage}`">
					</div>
					<div class="news_latest-news__container-desc">
						<p class="news_latest-news__container-desc__title">{{x.title}}</p>
						<div class="display-flex-sb">
							<span class="tags single">{{x.soccerLeagueName}}</span>
							<span class="font-s">{{x.publishedOn}}</span>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	  </div>
  </div> -->
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination,Navigation,Autoplay} from 'swiper/core';
SwiperCore.use([Pagination,Navigation,Autoplay]);
import MobileSelect from "mobile-select";//"@/lib/mobile-select/mobile-select";

import moment from 'moment'

import config from '@/js/config.js'
import MobileHeader from '@/components/mobile/Header.vue'
import NewCategoriesCard from '@/components/news/NewCategoriesCard.vue'
import {mapGetters,mapActions, mapMutations} from 'vuex'

export default {
	components:{
        Swiper,
        SwiperSlide,  
		MobileHeader,
		NewCategoriesCard     
    },
	watch:{
		$route() {		
			location.reload();
			//this.setSelectedNewsLang();			
		},
		userSwitchCountryLang: {
			handler(newVal, oldVal) {
				if (newVal && this.currentLanguageObj.displayName === "English") {
					this.setSelectedNewsLang();
				}
			}
		},
	},
	data() {
		return {
			s3ImgUrl: config.s3ImgUrl,
			newsCateogoriesList: [],
			topStoriesList: {},
			isShowTopStoriesSwiper: true,
			isLoading: true,
			isShowNoData: false,
			newsLangSelect: {
				selectedLangObj: null,
				isShowList: false,
				msInstance: null,
			},
			newsSportType: [
				{
					name: 'ALL',
					id: "ALL",
					pathName: 'news',
					selected: false
				},
				{
					name: 'FOOTBALL',
					id: "SOCCER",
					pathName: 'footballNews',
					selected: false
				},
				{
					name: 'BASKETBALL',
					id: "BASKETBALL",
					pathName: 'basketballNews',
					selected: false
				}
			],
			currentNewsSportType: "ALL"
		}
	},
	computed:{
		...mapGetters([
			'isMobile',
			'currentLanguageObj',
			"currentLocale",
			'currentSportType',
			'currentLanguageList',
			'isLogin',
			'userSwitchCountryLang',
		]),
	},
	mounted() {
		this.commonVerifyLogin();
		this.setSelectedNewsLang();
		this.handleNewsSportType();	
		setTimeout(() => {
				
		}, 500);

	},
	methods: {
		 ...mapMutations([
            "SET_USER_COUNTRY_CODE",
        ]),
		...mapActions([
			"getAllNewsList",
			"commonVerifyLogin",
			"getCountryCodeByIp",
			"currentCountryChangeEvent",
		]),

		setSelectedNewsLang(o = null) {
			if (o === null) {//this.newsLangSelect.selectedLangObj
				this.newsLangSelect.selectedLangObj = this.currentLanguageObj;				
			} else {
				this.newsLangSelect.selectedLangObj = o;
			}
				
			this.$nextTick(() => this.getAllNewsListData(this.currentNewsSportType));
		},

		mobileLangSelection(selectedLocale) {
			if (!this.isMobile) return;
			this.$nextTick(() => {
				let triggerId = "#mobileNewsLangSelection";
				let title = this.$i18n.t("SELECT_LANGUAGE");
				let ensureBtnText = this.$i18n.t("DONE");
				let cancelBtnText = this.$i18n.t("CANCEL");

				let ensureBtnColor = "#01C3F7";
				let titleBgColor = "#F0F0F0";
				let selectedIndex;

				if (this.newsLangSelect.msInstance === null) {
					let langSelectData = [];
					// store vue js this to that
					let that = this;
					
					this.currentLanguageList.forEach((ele, i) => {
						langSelectData.push({
							id: ele.locale,
							value: ele.displayName,
						});
						
						if (selectedLocale === ele.locale) {
							selectedIndex = i;
						}
					});

					this.newsLangSelect.msInstance = new MobileSelect({
						trigger: triggerId,
						triggerDisplayData: false,
						title: title,
						ensureBtnText: ensureBtnText,
						cancelBtnText: cancelBtnText,
						ensureBtnColor: ensureBtnColor,
						titleBgColor: titleBgColor,
						wheels: [{ data: langSelectData}],
						triggerDisplayValue: false,

						onShow: function() {
							that.newsLangSelect.isShowList = true;
						},

						onHide: function() {
							that.newsLangSelect.isShowList = false;
						},

						callback: function(indexArr, data) {
							let selectedLangObj = that.currentLanguageList.filter(ele => ele.locale === data[0].id);
							selectedIndex = indexArr;
							that.setSelectedNewsLang(selectedLangObj[0]);
						},
					});

					this.newsLangSelect.msInstance.locatePosition(0, selectedIndex);
				}
			});
		},

		async getAllNewsListData(sportType){
			
			if(!this.isLogin && !this.userCountryCode) {
                let result = await this.getCountryCodeByIp();
                if (result.message === "Success") {
                    let countryCode = result.result.countryCode;

                    // this.SET_USER_COUNTRY_CODE(countryCode);
             
                    this.currentCountryChangeEvent(countryCode);
                }
            }
		
			let params = {
				language: this.newsLangSelect.selectedLangObj.locale ?? this.currentLanguageObj.locale,
				sportType: sportType
			}
			
			this.isShowNoData = false;
			this.isLoading = true;
			this.mobileLangSelection(params.language);
		
			const result = await this.getAllNewsList(params);
			this.isLoading = false;
			

			//reset news data variable
			this.newsCateogoriesList = [];
			this.topStoriesList = [];

			let data = result.result;
			if (data && data.length > 0) {	
				for (let i = 0; i<data.length; i++) {
					data[i].urlName = data[i].name.toLowerCase().replace(/\s/g, '-')
					for (let x = 0; x<data[i].news.length; x++) {
						let convertedDate;
						let A = moment(data[i].news[x].publishedOn);
						let B = moment();
						let diffDays = (A.diff(B, 'days'));
					
						if (diffDays > 0) {
							convertedDate =  moment(data[i].news[x].publishedOn).fromNow();
						} else {
							convertedDate  =  moment(data[i].news[x].publishedOn).format('dddd D MMM YYYY')
						}
						let theNewsData = data[i].news[x];
						if (theNewsData.sportType == "SOCCER") {
							theNewsData.sportTypePath = "football";
						} else {
							theNewsData.sportTypePath = theNewsData.sportType.toLowerCase();
						}
						data[i].news[x].urlName = data[i].news[x].title.toLowerCase().replace(/\s/g, '-')
						data[i].news[x].publishedOn = moment(data[i].news[x].publishedOn).fromNow(); //moment("2019-09-10T07:53:06.000+00:00").fromNow(); //
					}
				}
			
				this.topStoriesList = data[0];
				if (this.topStoriesList.news.length > 3 ) {
					this.isShowTopStoriesSwiper = true;
				} else {
					this.isShowTopStoriesSwiper = false;
				}
			
				data.shift();
			
				this.newsCateogoriesList = data;
			} else {
				this.isShowNoData = true;
			}
			
						
		},

		handleNewsSportType(o) {
			let pathName = window.location.pathname;
			if (pathName.charAt(0) == "/") {
				pathName = pathName.substring(1);
			}
			let urlExtract = pathName.split("/");
			let currentSportType = "all";
			if (urlExtract.length > 2) {
				currentSportType = urlExtract[2];
			}
			
			for (let i = 0; i<this.newsSportType.length; i++) {
				this.newsSportType[i].selected = false;
				if (this.newsSportType[i].name.toLocaleLowerCase() === currentSportType) {
					this.newsSportType[i].selected = true;
					this.currentNewsSportType = this.newsSportType[i].id
				} else {
			
				}
			}
			// this.newsSportType.forEach(element => {
			// 	element.selected = false;
			// });
			// o.selected = true;
			// this.currentNewsSportType = o.id;
			this.getAllNewsListData(this.currentNewsSportType)
		},
		handleShowNewsLanguagelist(action) {
			if (action === 'enter') {
				this.newsLangSelect.isShowList = true;
			} else {
				this.newsLangSelect.isShowList = false;
			}
		},

		handleNewsLangSelect(o) {
			this.newsLangSelect.isShowList = false;
			this.setSelectedNewsLang(o);
		},

		mobileToggleLangShow() {
			this.newsLangSelect.isShowList = !this.newsLangSelect.isShowList;
		},
	},
}
</script>

<style scoped>
.news-header{
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.news-container{
	overflow: unset;
}
.news_latest-news{
	margin-bottom: 3rem;
}
.news-page-main-wrapper {
	display: flex;
	justify-content:space-between;
	align-items: flex-start;
	margin-bottom: 1.8rem;	
	margin-top: 1rem;
}
.news-language__selection-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.news-language__selected-pill {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 7.3rem;
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #FFFFFF;
	border-radius: 1.5rem;
	padding: 0.25rem 0.7rem;
	font-size: 0.875rem;
	font-weight: 700;
	cursor: pointer;
}
.news-language__selected-pill .news-language__list-caret {
	margin-left: 0.5rem;
	display: flex;
	align-items: center;
	transition: all 0.3s;
}
.news-language__selected-pill .news-language__list-caret.active {
	transform: rotateX(180deg);
}
.news-language-list__wrapper {
	position: absolute;
	top: 100%;
	z-index: 2;
	background: var(--color-grey-60);
	border-radius: 0.7rem;
	min-width: 12.5rem;
	transform: scale(0);
	transform-origin: 100% 0;
	transition: all 0.3s;
	padding: 0.5rem 0;
}
.news-language-list__wrapper.active {
	transform: scale(1);
}
.news-language-list__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
	margin-bottom: 0.375rem;
	border-radius: 0.3rem;
	cursor: pointer;
}
.news-language-list__container:last-child {
	margin-bottom: 0;
}
.news-language-list__container:hover,
.news-language-list__container.selected {
	background: var(--color-grey-40);
}
.news-language-list__name {
	font-size: 0.875rem;
	font-weight: 400;
}
.news-language-list__tick {
	width: 1rem;
	height: 1rem;
}
.news-language-list__tick.selected {
	background: url("../../../static/images/icons/icon_tick_selected.png") center/cover no-repeat;
	background-size: 12px 10px;
}
.news_top-stories__title{
	font-size: 2.125rem;
	font-weight: 700;

}
.news_top-stories__wrapper{
	position: relative;
	margin: 0 auto;
	margin-bottom: 2.5rem;
}
.news_top-stories__img-wrapper{
	padding-top: 58%;
	position: relative;
}
.news_top-stories__img-coverImg{
	object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.news_top-stories__img-icon{
	position: absolute;
    bottom: 1.25rem;
    left: 1.25rem;
}
.news__top-stories-btn {
	position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    display: flex;
    width: 2rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.news__top-stories-btn.next{
	right: -2rem;
}
.news__top-stories-btn.prev{
	left: -2rem;
}
.news_top-stories__desc-wrapper{
	background-color: var(--color-theme);
	padding: 1rem;
	height: 4rem;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.news_top-stories__desc-title{
	font-size: 1rem;
    font-weight: 700;
	 display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.news_top-stories__desc-tag{
	font-size: 0.6rem;
}
.news_top-stories__desc-seperator{
	margin: 0 .5rem;
}

.news_latest-news__title-wrapper{
	display: flex;
	justify-content: space-between;
	border-bottom: .1rem solid var(--color-grey-60);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.news_latest-news__title{
	font-size: 0.875rem;
	font-weight: 700;
}

.news_latest-news__container{
	display: flex;
	padding-bottom: 1rem;
	border-bottom: .1rem solid var(--color-grey-60);
		
}
.news_latest-news__container-img{
	margin-right: 1rem;
	position: relative;
    width: 13.31rem;
    height: 7.5rem;
}
.news_latest-news__container-img img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.news_latest-news__wrapper{
	display: grid;
	grid-template-columns: repeat(2,1fr);
	column-gap: 1rem;
    row-gap: 1rem
}
.news_latest-news__container-desc{
	padding: 1rem 1rem 1rem 0;
	display: flex;
	flex: 1;
    flex-direction: column;
    justify-content: space-between;
}
.news_latest-news__container-desc p{
	 display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
   
.news_latest-news__container-desc_tag{
	font-size: 0.6rem;
}
.news_latest-news__container-desc_tag::after{
	content: '|';
	width: .1rem;
	height: 1rem;
	margin: 0 .5rem;
}
.news_latest-news__container-desc_tag:last-of-type::after {
	display: none;
}
/* @media (min-width: 768px){
	.mySwiper {
		padding-bottom: 2rem;
	}
	.mySwiper .swiper-pagination {
		bottom: 0;
	}
} */
@media (max-width: 768px){
	.news-container{
		overflow: hidden;
	}
	.news_top-stories__img-wrapper{
		/* padding-top: 0;
		width: 10rem;
		height: 10rem; */
	}
	.news_top-stories__img-wrapper{

	}
	.news_top-stories__swipper{
		overflow: unset;
	}
	.news_top-stories__desc-title{
		font-size: 0.875rem;
		line-height: 1.4;
	}
	.news-page-main-wrapper {
		margin-bottom: 0.75rem;
		margin-top: 1rem;
	}
	.news_top-stories__title{
		font-size: 0.875rem;
		font-weight: 700;
	}
	.news_latest-news__wrapper{
	    display: flex;
    	flex-direction: column;
	}
	.news_latest-news__container-img{
		flex: 0 0 29%;
		padding-top: 19%;
		width: auto;
		height: auto;
	}
	.news_latest-news__container-desc{
		padding: 0;
	}
	.news_latest-news__title-wrapper{
		border-bottom: none;
	}
	.news_latest-news__container-desc__title{
		font-size: 0.875rem;
	}
}
@media (min-width: 768px){
	.mySwiper {
		padding-bottom: 2rem;
	}
	.mySwiper .swiper-pagination {
		bottom: 0;
	}
}
</style>